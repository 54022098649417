import { useState, type ReactNode } from "react";
import Image from "next/image";
import { cn } from "@/lib/utils";
import { PiArrowLeftBold as ArrowLeft, PiArrowRightBold as ArrowRight } from "react-icons/pi";
import { FiType, FiFilm, FiMusic } from "react-icons/fi";
import { IoIosColorFilter } from "react-icons/io";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { RedditSocialCard } from "./RedditSocialCard";

const previewData = {
  script: "Turn minutes into millions of views. Going viral has never been this easy.",
  introCard: {
    pfp: "https://www.redditstatic.com/avatars/avatar_default_02_0079D3.png",
    name: "Crayo AI",
    content: "Generate posts for your next video directly in the Crayo AI editor.",
    darkMode: false,
  },
};

export const RedditStoryEditor = () => {
  const [username, setUsername] = useState("");
  const [content, setContent] = useState("");
  const [script, setScript] = useState("");
  const [darkMode, setDarkMode] = useState(false);

  return (
    <StoryEditorLayout>
      <div className="flex h-full w-full flex-col items-start justify-between space-y-2 overflow-hidden pb-3 @xl:flex-row @xl:space-x-2 @xl:space-y-0 @xl:pb-0 @2xl:space-x-4">
        <div className="flex h-full w-full flex-col space-y-3 rounded-xl border border-[#E1E1E1] bg-white p-3 @xl:h-[490px] @xl:space-y-3 @4xl:p-4">
          <div className="flex h-full w-full flex-col ">
            <div className="flex w-full flex-row items-center justify-between">
              <p className="text-sm font-semibold text-[#0E121B]">
                Generate <span className="hidden @sm:inline @xl:hidden @2xl:inline">Story</span>
                &nbsp;Video
              </p>
              <div className="flex items-center space-x-1.5">
                <div
                  className={cn(
                    "flex cursor-pointer items-center justify-center space-x-1.5 rounded-full border border-[#E1E4EA] bg-white px-3 py-2",
                    "hidden @sm:flex @xl:hidden @4xl:flex",
                  )}
                >
                  <p className="text-center text-xs font-medium @md:text-sm">Sample Data</p>
                </div>
                <div className="flex cursor-pointer items-center justify-center space-x-1.5 rounded-full px-3 py-2 text-white shadow-[0px_1px_2px_rgba(27,_28,_29,_0.48)] [background:linear-gradient(180deg,_rgba(255,_255,_255,_0.16),_rgba(255,_255,_255,_0)),_#335cff] @xl:px-2 @2xl:px-3">
                  <p className="text-center text-xs font-medium text-white @md:text-sm">
                    AI Script
                  </p>
                </div>
              </div>
            </div>
            <hr className="mt-2 w-full border-gray-200" />
          </div>
          <div className="flex flex-col space-y-2 @xl:space-y-4">
            <div className="h-full w-full rounded-xl border border-[#E1E1E1] p-4">
              <p className="flex items-center text-sm font-semibold text-[#3D4359]">
                Introduction Card
              </p>
              <div className="pt-2">
                <div className="flex flex-col items-start justify-between space-x-3 space-y-2 @xl:flex-row @xl:space-y-0">
                  <div className="flex w-full space-x-2">
                    <Image
                      alt="reddit pfp"
                      className="size-9 rounded-full object-cover"
                      width={10}
                      height={10}
                      src={previewData.introCard.pfp}
                      draggable={false}
                    />
                    <div className="flex w-full flex-col space-y-4">
                      <div className="flex flex-col space-y-2">
                        <div className="flex flex-row space-x-2">
                          <Input
                            className="rounded-lg text-xs text-[#0E121B] focus:border-gray-800 focus:outline-none"
                            placeholder="Name"
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                        </div>
                        <Textarea
                          className="rounded-lg text-xs text-[#0E121B] focus:border-gray-800 focus:outline-none"
                          placeholder="Content"
                          maxLength={200}
                          value={content}
                          onChange={(e) => setContent(e.target.value)}
                        />
                        <button
                          className={cn(
                            "w-full cursor-pointer rounded-lg border border-gray-200 bg-white p-2",
                            darkMode && "bg-black text-white",
                          )}
                          onClick={() => setDarkMode(!darkMode)}
                        >
                          <p className={cn("text-center text-xs", darkMode && "text-white")}>
                            {darkMode ? "Light Mode" : "Dark Mode"}
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-full w-full rounded-xl border border-[#E1E1E1] p-4">
              <div className="flex w-full items-center justify-between">
                <p className="text-sm font-semibold text-[#3D4359]">Video Script</p>
              </div>
              <div className="pt-2">
                <Textarea
                  className="text-xs text-[#0E121B] focus:border-gray-800 focus:outline-none"
                  placeholder="Enter video script content..."
                  rows={6}
                  onChange={(e) => setScript(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col space-y-2 rounded-xl border border-[#E1E1E1] bg-white p-3 @xl:h-[490px] @xl:w-3/4 @4xl:p-4">
          <div className="flex flex-col space-y-2">
            <p className="pt-0.5 text-sm font-semibold text-[#0E121B]">Intro Preview</p>
            <RedditSocialCard
              userAvatar={previewData.introCard.pfp}
              userName={username || previewData.introCard.name}
              content={content || previewData.introCard.content}
              darkMode={darkMode}
            />
          </div>
          <div className="flex h-full w-full flex-col space-y-3 pt-2">
            <p className="text-sm font-semibold leading-none text-[#0E121B]">Script Preview</p>
            <div className="flex rounded-xl bg-gray-100 p-3">
              <p className="text-pretty text-sm text-gray-600">{script || previewData?.script}</p>
            </div>
          </div>
        </div>
      </div>
    </StoryEditorLayout>
  );
};

const StoryEditorLayout = ({ children }: { children: ReactNode }) => (
  <div className="@container">
    <div className="flex h-full w-full rounded-lg border border-gray-50 bg-white @md:border-[#ECECEE] @md:p-2">
      <div
        className="relative flex h-full w-full flex-col overflow-hidden rounded-lg border border-[#ECECEF] py-1 transition-all duration-200 ease-in-out @xl:flex-row @xl:p-2.5 @2xl:px-4 @2xl:py-5"
        style={{
          background: "linear-gradient(110.38deg, #F8F8FF -0.36%, #F9F9FF 112.86%)",
        }}
      >
        {/* desktop */}
        <div className="z-50 hidden min-h-full flex-col space-y-2 @xl:mr-2 @xl:flex @2xl:mr-4">
          <div className="flex cursor-pointer select-none flex-row items-center justify-center space-x-1 rounded-lg border border-gray-200 bg-white py-4">
            <ArrowLeft className="size-4" strokeWidth={0.1} />
            <p className="text-xs font-medium text-gray-700">Back</p>
          </div>
          <div className="flex h-full w-full flex-col items-center justify-between space-y-2 rounded-lg border border-gray-200 bg-white px-2 pb-4 pt-3">
            <div>
              {[
                { name: "Script", icon: FiType },
                { name: "Style", icon: IoIosColorFilter },
                { name: "Video", icon: FiFilm },
                { name: "Audio", icon: FiMusic },
              ].map((item) => (
                <div
                  key={item.name}
                  className="relative flex cursor-pointer flex-col items-center justify-center space-y-1.5 rounded-md p-3 text-[#6B7280]"
                >
                  <div
                    className={cn(
                      "rounded-full bg-white p-2",
                      item.name === "Script" && "bg-[#F3F4F6]",
                    )}
                  >
                    <item.icon className="size-5" />
                  </div>
                  <p className="text-xs text-[#6B7280]">{item.name}</p>
                </div>
              ))}
            </div>
            <Image
              alt="step-progress"
              className="size-10 rounded-full object-cover"
              width={10}
              height={10}
              src="https://cdn-crayo.com/lp/public/landing/progress.svg"
              draggable={false}
            />
          </div>
        </div>

        <div className="invisible-scrollbar z-10 flex h-full w-full flex-col px-2 @sm:px-3 @xl:justify-between @xl:space-y-0 @xl:rounded-xl @xl:px-0">
          {/* mobile */}
          <div className="flex w-full flex-row items-center justify-between border-t border-white border-opacity-10 pr-3 @xl:hidden">
            <div className="flex w-full flex-row items-center space-x-2 py-1.5 pl-2">
              {[
                { name: "Script", icon: FiType },
                { name: "Style", icon: IoIosColorFilter },
                { name: "Video", icon: FiFilm },
                { name: "Audio", icon: FiMusic },
              ].map((item: any, i: number) => (
                <div
                  className={cn(
                    "flex aspect-square w-fit flex-col items-center justify-center space-y-1 rounded-md p-1 text-gray-500 transition-all duration-200 ease-in-out hover:text-blue-400",
                    item.name === "Script" && "text-blue-400",
                  )}
                  key={item.name}
                  onClick={() => {}}
                >
                  <div className="rounded-full bg-white p-2">
                    <item.icon className="size-4" />
                  </div>
                  <p
                    className={cn(
                      "text-[10px] font-medium",
                      item.name === "Script" && "text-blue-400",
                    )}
                  >
                    {item.name}
                  </p>
                </div>
              ))}
            </div>
            <Image
              alt="step-progress"
              className="size-10 rounded-full object-cover"
              width={10}
              height={10}
              src="https://cdn-crayo.com/lp/public/landing/progress.svg"
              draggable={false}
            />
          </div>

          <div className="mx-auto flex h-full w-full flex-col">
            <div className="flex h-full w-full">{children}</div>

            {/* step buttons */}
            <div className="hidden h-full w-full translate-y-1.5 items-center justify-center space-x-2 @xl:flex @2xl:translate-y-2.5">
              <div className="flex w-32 cursor-pointer items-center justify-center space-x-1.5 rounded-full border border-[#E1E4EA] bg-white py-1.5 text-xl text-black">
                <ArrowLeft className="size-4 text-gray-500" />
                <p className="text-center text-sm font-medium text-gray-500 @2xl:text-base">Back</p>
              </div>
              <div className="flex w-32 cursor-pointer items-center justify-center space-x-1.5 rounded-full bg-gray-700 py-1.5">
                <p className="text-center text-sm font-medium text-white @2xl:text-base">Next</p>
                <ArrowRight className="size-4 text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
